/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Obfuscate from "react-obfuscate"

import Footer from "../components/footer"
import SEO from "../components/seo"
import Layout from "../components/layout"

const Contact = props => {
  return (
    <Layout>
      <SEO
        title="Contact | tinyML Foundation"
        description="TinyML Summit. The topic is advances in ultra-low power Machine Learning technologies and applications."
      />
      <div className="grid-container gap-xxxl" sx={{ paddingTop: `200px` }}>
        <h1 className="gap-l">Contact</h1>
        <div className="grid-x">
          <div
            className="large-6 cell"
            sx={{
              marginBottom: `25px`,
              "@media (min-width: 768px)": {
                textAlign: `center`,
                alignSelf: `center`,
                margin: "0 auto",
              },
            }}
          >
            <ul sx={{ listStyle: `none`, margin: `0` }}>
              {/* <li>
                <h3 sx={{ paddingBottom: `15px` }}>tinyML Events Organizer</h3>
              </li> */}
              <li>
                <h4>Rosina HABERL</h4>
              </li>
              <li>
                <p>+1 (415) 287-3276</p>
              </li>
              <li>
                <Obfuscate email="rosina@tinyML.org" />
              </li>
              <br />
              <li>
                <a
                  href="http://eepurl.com/gXME-9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>Join our E-Mail list</button>
                </a>
              </li>
              {/* <li>
                <a
                  href="https://form.jotform.com/201475685429969"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>Join our interest list</button>
                </a>
              </li> */}
            </ul>
          </div>
          <div className="large-6 cell contact-img" sx={{ margin: `0 auto` }}>
            <Img
              fluid={props.data.contact.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    contact: file(relativePath: { eq: "Rosina-Haberl.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allWpEventPage {
      edges {
        node {
          title
          id
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Contact
